import * as React from 'react'

const NotFoundPage = () => (
  <div>
    <h1>Page not found</h1>
    <p>Not all roads lead to somewhere. At least not on this website!</p>
  </div>
)

export default NotFoundPage
